import { getLocalTimeZone } from '@internationalized/date';
import { createContext, useContext } from 'react';

type TimeZoneProviderProps = {
	timeZone: string;
	children: React.ReactNode;
};

const Context = createContext<string>(getLocalTimeZone());

export function TimeZoneProvider({ timeZone, children }: TimeZoneProviderProps) {
	return <Context.Provider value={timeZone}>{children}</Context.Provider>;
}

export function useTimeZone() {
	const timeZone = useContext(Context);

	if (!timeZone) {
		throw new Error('Please wrap your application in a TimeZoneProvider.');
	}

	return { timeZone };
}
